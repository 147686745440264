angular.module('sq.utils.service', []).factory('sqUtils', utilsFactory);

function utilsFactory($log) {
  var u = {};

  // TODO: just make a left hand version of angular extend; to avoid copy step
  u.leftMerge = function leftMerge(dst, sources) {
    var src = angular.copy(sources);
    if (angular.isArray(src) ) {
      angular.forEach(src, function(obj) {
        u.lefMergeOne(dst, obj);
      });
      return;
    }
    u.lefMergeOne(dst, src);
  };

  u.lefMergeOne = function leftMergeOne(dst, src) {
    if (!angular.isObject(src) ) {
      $log.error('only objects allowed in merge');
      return;
    }
    angular.forEach(src, function(val, prop) {
      if (!dst.hasOwnProperty[prop]) {
        dst[prop] = val;
      }
    });
  };

  return u;
}
